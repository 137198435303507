import React from 'react'
import { Outlet } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Header from '../components/Header'
import WhatsApp from '../components/Whatsapp';
import Footer from '../components/Footer'

const Layout = () => {
  return (
    <>
        <NavBar />
        <Header />
        <Outlet />
        <WhatsApp />
        <Footer />
       
    </>
  )
}


export default Layout;




