import React from 'react';
import { Button, Typography, Container} from '@mui/material';
import { styled } from '@mui/system';

const Background = styled('div')({
  textAlign: 'center',
  backgroundColor: '#ecfadc',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const ErrorText = styled(Typography)({
  fontSize: '150px',
  color: '#DAA520',
  textShadow: `
    1px 1px 1px #DAA520',
    2px 2px 1px #DAA520',
    3px 3px 1px #DAA520',
    4px 4px 1px #DAA520',
    5px 5px 1px #DAA520',
    6px 6px 1px #DAA520',
    7px 7px 1px #DAA520',
    8px 8px 1px #DAA520',
    25px 25px 8px rgba(0,0,0, 0.2)
  `,
});

const PageText = styled(Typography)({
  margin: '2rem 0',
  fontSize: '20px',
  fontWeight: 600,
  color: '#000',
});

const BackHomeButton = styled(Button)({
  border: '2px solid #222',
  color: '#fff',
  textTransform: 'uppercase',
  fontWeight: 600,
  padding: '0.75rem 1rem 0.6rem',
  transition: 'all 0.2s linear',
  boxShadow: '0 15px 15px -11px rgba(0,0,0, 0.4)',
  backgroundColor: '#111',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#111',
    color: '#eee',
  },
});

const ErrorPage = () => {
  return (
    <Background>
      <Container>
        <ErrorText variant="h1">404</ErrorText>
        <PageText variant="body1">Ooops!!! La página que buscas no se encuentra</PageText>
        <BackHomeButton variant="contained" href="https://ferracort.com/">
          Página Principal
        </BackHomeButton>
      </Container>
    </Background>
  );
};

export default ErrorPage;
