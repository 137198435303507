import React, { useState } from 'react';
import { AppBar, IconButton, Toolbar, Typography, Box, Button, Menu as MuiMenu, MenuList, MenuItem, createTheme, ThemeProvider } from '@mui/material';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import logo from '../assets/img/logo.png'
import CabinIcon from '@mui/icons-material/Cabin';

// Define the dark theme
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#000000',
        },
        background: {
            default: '#fff',
            paper: '#000000',
        },
        text: {
            primary: '#ffffff',
        },
    },
});

// Define the HideOnScroll component
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

// Define the NavBar component
const NavBar = (props) => {
    const [anchorNav, setAnchorNav] = useState(null);

    const openMenu = (event) => {
        setAnchorNav(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorNav(null);
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <HideOnScroll {...props}>
                <AppBar position='sticky'>
                    <Toolbar>
                        <Box
                            size='large'
                            edge="start"
                            color='inherit'
                            aria-label='logo'
                            sx={{ display: { xs: 'flex', md: 'flex',color:'yellow' } }}
                        >
                            
                            <img width='30px' src={logo} alt="logo" />
                            
                        </Box>

                        <Typography  
                            className="custom-logo"
                            variant="h6"
                            color='inherit'
                            component='div'
                            sx={{
                                flexGrow: 1,
                                fontSize: '1rem',
                                
                                textTransform: 'uppercase',
                                letterSpacing: '1.3px',
                            }}
                        >
                            FERRACORT
                        </Typography>

                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <StyledButton className="custom-title" color='inherit' to="/" component={Link}>Principal</StyledButton>
                            <StyledButton className="custom-title" color='inherit' to="/about" component={Link}>Nosotros</StyledButton>
                            <StyledButton className="custom-title" color='inherit' to="/contact" component={Link}>Contacto</StyledButton>
                        </Box>

                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size='large'
                                edge='end'
                                color='inherit'
                                onClick={openMenu}
                            >
                                <MenuContainer className="custom-title">
                                    <span>M</span>
                                    <CabinIcon  />
                                    <span>NU</span>
                                </MenuContainer>
                            </IconButton>
                            <MuiMenu
                                anchorEl={anchorNav}
                                open={Boolean(anchorNav)}
                                onClose={closeMenu}
                                sx={{ display: { xs: 'flex', md: 'none' } }}
                            >
                                <MenuList>
                                    <MenuItem className="custom-logo" onClick={closeMenu} sx={{ color: 'white', fontSize: '0.9rem', letterSpacing:'2.5px' }} component={Link} to="/">Principal</MenuItem>
                                    <MenuItem className="custom-logo" onClick={closeMenu} sx={{ color: 'white', fontSize: '0.9rem', letterSpacing:'2.5px' }} component={Link} to="/about">Nosotros</MenuItem>
                                    <MenuItem className="custom-logo" onClick={closeMenu} sx={{ color: 'white', fontSize: '0.9rem', letterSpacing:'2.5px' }} component={Link} to="/contact">Contacto</MenuItem>
                                </MenuList>
                            </MuiMenu>
                        </Box>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
        </ThemeProvider>
    );
};

export default NavBar;

const MenuContainer = styled.nav`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2rem;
    span {
        font-size: 1.2rem;
        color: yellow;
        font-weight: 700;
        letter-spacing: 3px;
    }
`;

const StyledButton = styled(Button)`
    && {
        font-size: 0.9rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 0.5rem 1rem;
        margin-right: 1rem;

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
`;
