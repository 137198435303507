import React from 'react'
import Galeria from '../components/Galeria';
import Carousel from '../components/Carousel'
import ExperienciaCounter from '../components/ExperienciaCounter';
import MapComponent from '../components/MapComponents.jsx';
import {Paper } from '@mui/material';

const Home = () => {
  return (
  <>
    <Galeria/>
    <Carousel />
    <ExperienciaCounter />
    <Paper elevation={3} style={{ width: '100%', maxWidth: '600px', margin: '0 auto', padding: '16px' }}>
          <MapComponent />
    </Paper>
  </>
  )
}


export default Home;
