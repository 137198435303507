// src/components/Galeria.js
import React from 'react';
import { Box, Card, CardActions, CardContent, CardMedia, Button, Typography, Grid } from '@mui/material';
import foto1 from '../assets/img/productos/foto1.jpg';
import foto2 from '../assets/img/productos/foto2.jpg';
import foto3 from '../assets/img/productos/foto-3m.png';
import foto4 from '../assets/img/productos/foto4.jpg'
import CenteredTitle from './CenteredTitle'; // Asegúrate de importar el componente

const cardData = [
  {
    id: 1,
    title: 'Persiana de Aluminio Inyectada con Poliuretano Lama',
    description: 'Medidas: 43, 45, 55, 77, 95 y 125 - Colores: blanco, negro, gris plata, marrón veteado',
    image: foto1,
    whas:'https://walink.co/40b147',
  },
  {
    id: 2,
    title: 'Persianas de aluminio con Cajón compacto',
    description: 'Además, ofrecemos servicios de motorización de persianas con control remoto',
    image: foto2,
    whas:'https://walink.co/9c4fd4',
  },{
    id: 3,
    title: 'Persianas de PVC económicas, reforzadas y super reforzadas',
    description: 'Además, contamos con herrajes',
    image: foto3,
    whas:'https://walink.co/a9f094',

  }, {
    id: 4,
    title: 'Persiana Chapa galvanizada reforzada y microperforada (0,70)',
    description: 'Instala persianas metálicas de alta resistencia para mejorar la seguridad de tu local. ',
    image: foto4,
    whas:'https://wa.link/ua910q',

  }
 
  // Agrega más objetos según sea necesario
];

const Galeria = () => {
  return (
    <Box sx={{ padding: 6, color:'#DAA520', background:'#000' }}>
      <CenteredTitle title="OFRECEMOS" className="custom-title" /> {/* Añadir el título centrado aquí */}
      <Grid container justifyContent="center"  alignItems="center">
        {cardData.map((card) => (
          <Card sx={{ maxWidth: 330, minHeight: 450, margin: 2, display: 'flex', flexDirection: 'column' }} key={card.id}>
            <CardMedia
              component="img"
              alt={card.title}
              height="200"
              image={card.image}
              style={{ objectPosition: '100% 17%' }}
            />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography className='custom-second' gutterBottom variant="h5" component="div">
                {card.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {card.description}
              </Typography>
            </CardContent>
            <CardActions>
              <Button 
               href={card.whas}
                variant="contained" 
                sx={{ 
                  backgroundColor: '#DAA520', 
                  '&:hover': {
                    backgroundColor: 'black'
                  } 
                }}
              >
                COMPRAR
              </Button>
            </CardActions>
          </Card>
        ))}
      </Grid>
    </Box>
  );
}

export default Galeria;
