// src/components/Footer.jsx
import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { Phone,  Room } from '@mui/icons-material';
import logo from '../assets/img/logo.png'
import Avatar from '@mui/material/Avatar';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#151414', color: 'white', textAlign: 'center', paddingTop: 3, paddingBottom: 3 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3} marginTop='1.5rem'>
              <Grid item xs={12} sm={4}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Room sx={{ fontSize: '2rem', color: '#DAA520' }} />
                  <Typography variant="h6" className='custom-second'  >Ubicación</Typography>
                  <Typography sx={{color: '#DAA520'}} className='custom-second'>BUENOS AIRES</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Phone sx={{ fontSize: '2rem', color: '#DAA520' }} />
                  <Typography variant="h6" className='custom-second'   >Celular</Typography>
                  <Typography sx={{color: '#DAA520'}} className='custom-second'>Comunicate al whatsapp</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box textAlign="center">
              <Box display='flex' justifyContent='center' alignItems='center'>
                <Avatar alt="Remy Sharp" sx={{ width: 140, height: 110, alignItems: 'center' }} src={logo} />
                <Typography  className="custom-logo" sx={{fontSize:'1.5rem'}}>
                 FERRACORT
                </Typography>
              </Box>
              <Typography className='custom-second' sx={{ marginTop: 2 }}>
                "Con más de tres décadas de experiencia, somos líderes en la venta de persianas de aluminio de alta calidad, inyectadas con poliuretano y PVC. Ofrecemos soluciones duraderas y estilizadas para mejorar cualquier espacio."
              </Typography>
              
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box mt={4}>
        <Typography variant="body2">&copy; 2020, All Rights Reserved</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
