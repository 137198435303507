import React from 'react';
import styled, { keyframes } from 'styled-components';
import { IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const breatheAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(37, 211, 102, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

const beatAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const FloatingButton = styled(IconButton)`
  && {
    position: fixed;
    bottom: 50px;
    right: 20px;
    z-index: 9999;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #25D366;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    animation: ${breatheAnimation} 2s ease-in-out infinite;
  }
`;

const StyledWhatsAppIcon = styled(WhatsAppIcon)`
  && {
    color: #fff;
    font-size: 24px;
    animation: ${beatAnimation} 2s ease-in-out infinite;
  }
`;

const WhatsAppButton = () => {
  return (
    <FloatingButton 
      href="https://walink.co/0496e7" 
      target="_blank"
    >
      <StyledWhatsAppIcon />
    </FloatingButton>
  );
};

export default WhatsAppButton;
