import React from 'react';
import { Container, Typography, Grid, Paper, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/system';

const RootContainer = styled(Container)({
  flexGrow: 1,
  marginTop: theme => theme.spacing(4),
});

const StyledPaper = styled(Paper)({
  padding: theme => theme.spacing(3),
  textAlign: 'left',
  color: theme => theme.palette.text.secondary,
  boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
});

const TitleTypography = styled(Typography)({
  marginBottom: theme => theme.spacing(3),
});

const Information = () => {
  return (
    <RootContainer sx={{marginBottom: '2rem' }}>
      <TitleTypography className="custom-title" variant="h4" sx={{color:'#000', marginBottom:'1.5rem'}} align="center">
        Información de Contacto
      </TitleTypography>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <StyledPaper>
            <List>
              <ListItem>
                <ListItemText
                  primary={<Typography className="custom-title" variant="h6">Dirección:</Typography>}
                  secondary="Buenos Aires"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<Typography className="custom-title" variant="h6">Teléfono:</Typography>}
                  secondary="01150279026"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<Typography className="custom-title" variant="h6">Horario de Atención:</Typography>}
                  secondary="Lunes a viernes de 8:00 a 20:00 hs."
                />
              </ListItem>
            </List>
          </StyledPaper>
        </Grid>
      </Grid>
    </RootContainer>
  );
};

export default Information;
