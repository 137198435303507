import React from 'react'
import ErrorPage from '../components/ErrorPage';

const Layout = () => {
  return (
    <>
        <ErrorPage />
    </>
  )
}


export default Layout;