import React from 'react';
import { Typography, Box } from '@mui/material';

const CenteredTitle = ({ title, className }) => {
  return (
    <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
      <Typography className={className} variant="h4" align="center" gutterBottom>
        {title}
      </Typography>
    </Box>
  );
};

export default CenteredTitle;
