import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const MapComponent = () => {
  const position = [-34.61225, -58.41931]; // Coordenadas de tu negocio

  const customIcon = L.divIcon({
    className: 'custom-div-icon',
    html: "<div style='background-color:#2A2A2A;border-radius:8px;padding:10px;color:#fff;'>Aquí está mi negocio</div>",
    iconSize: [150, 42],
    iconAnchor: [75, 21]
  });

  return (
    <MapContainer center={position} zoom={13} style={{ height: '300px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position} icon={customIcon}>
      </Marker>
    </MapContainer>
  );
}

export default MapComponent;
