import React from 'react'
import Information from '../components/Information'

const Contact = () => {
  return (
   <>
    <Information />
   </>
  )
}

export default Contact;