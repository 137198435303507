// src/components/InstitutionCounter.js
import React, { useState, useRef, useEffect } from 'react';
import CountUp from 'react-countup';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import GroupIcon from '@mui/icons-material/Group';
import CenteredTitle from './CenteredTitle'; // Asegúrate de importar el componente

const ExperienciaCounter = () => {
  const [startCount, setStartCount] = useState(false);
  const counterRef = useRef(null);

  useEffect(() => {
    const currentRef = counterRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setStartCount(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.5 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <Box ref={counterRef} sx={{ padding: 6, color:'#DAA520' }}>
      <CenteredTitle title="Trayectoria" className="custom-title" />
      <Grid container spacing={4} justifyContent="center">
        <Grid item>
          <Card sx={{ minWidth: 275, textAlign: 'center', padding: 2 }}>
            <CardContent>
              <Typography  variant="h5" component="div">
                EXPERIENCIA
              </Typography>
              <WorkIcon style={{ fontSize: 40, color: '#EABE2a' }} />
              <Typography  variant="h3" component="div">+
                {startCount && <CountUp end={30} duration={2.5} />} AÑOS
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card sx={{ minWidth: 275, textAlign: 'center', padding: 2 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                CLIENTES
              </Typography>
              <GroupIcon style={{ fontSize: 40, color: '#EABE2a' }} />
              <Typography variant="h3" component="div">+
                {startCount && <CountUp end={15400} duration={2.5} />}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExperienciaCounter;
