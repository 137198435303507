import React from 'react'
import AboutUs from '../components/AboutUs';


const About = () => {
  return (
    <>
      <AboutUs />
    </>    
  )
}

export default About;
