import React from 'react';
import { Box, Typography, Button, Container, Link as MuiLink } from '@mui/material';
import { styled, keyframes } from '@mui/system';

import header from '../assets/img/header.jpg';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';


// Definir la animación
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const HeroSection = styled('section')({
  position: 'relative',
  overflow: 'hidden',
  height: '765px',
  padding: '3rem',
  '@media (max-width: 991px)': {
    height: 'auto',
    padding: '130px 0',
    
  },
});

const BackgroundImage = styled('div')({
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'left bottom',
  backgroundSize: 'cover',
  backgroundImage: `url(${header})`,
});

const BackgroundOverlay = styled('div')({
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
  backgroundColor: 'rgba(0,0,0, 0.5)',
  
  backgroundPosition: '600px -40px',
  backgroundSize: '48% 65%',
  backgroundRepeat: 'no-repeat',
  animation: 'fadeIn 0.5s ease-in-out 0.3s forwards',
  '@media (max-width: 1366px)': {
    backgroundImage: 'none',
    backgroundColor: '#222',
    mixBlendMode: 'hard-light',
  },
});

const ContentBox = styled('div')({
  position: 'relative',
  maxWidth: '540px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  animation: `${fadeInUp} 1s ease-in-out 0.6s forwards`,
  '@media (max-width: 991px)': {
    maxWidth: '100%',
    textAlign: 'center',
  },
});

const HeroSubtitle = styled(Typography)({
  display: 'block',
  fontSize: '1.5rem',
  lineHeight: '22px',
  color: '#fff',
  fontWeight: 700,
  letterSpacing: '.8px',
  marginBottom: '33px',
  opacity: 0,
  animation: `${fadeInUp} 0.6s ease-in-out 0.6s forwards`,
});

const HeroTitle = styled(Typography)({
  color: '#fff',
  lineHeight: '1.1em',
  letterSpacing: '-3px',
  textTransform: 'capitalize',
  marginBottom: '28px',
  fontSize: '4rem',
  fontWeight: 600,
  opacity: 0,
  animation: `${fadeInUp} 0.6s ease-in-out 0.9s forwards`,
  '@media (max-width: 575px)': {
    fontSize: '44px',
  },
});

const HeroDesc = styled(Typography)({
  color: '#fff',
  marginBottom: '40px',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '32px',
  letterSpacing: '1.5px',
  opacity: 0,
  animation: `${fadeInUp} 0.6s ease-in-out 1.2s forwards`,
  '@media (max-width: 575px)': {
    fontSize: '15px',
  },
});

const CtaButton = styled(Button)({
  fontSize: '16px',
  lineHeight: '24px',
  padding: '20px 40px',
  fontWeight: 600,
  color: '#fff',
  background: '#DAA520', // Dorado
  letterSpacing: '.8px',
  borderRadius: '7px',
  textTransform: 'none',
  textDecoration: 'none',
  display: 'inline-flex',
  justifyContent: 'center',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  transition: 'all .3s ease',
  '&:hover': {
    boxShadow: '0 10px 30px rgba(0, 0, 0, .1)',
    color: '#EABE3F',
  },
  '&::before': {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    content: '""',
    borderRadius: '7px',
    transform: 'scaleX(0)',
    transformOrigin: 'top right',
    transition: 'transform .5s cubic-bezier(.86, 0, .07, 1)',
    backgroundColor: '#EABE2a',
  },
  '&:hover::before': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
});

const Footer = styled('footer')({
  position: 'fixed',
  width: '100%',
  background: '#333',
  zIndex: 999,
  bottom: 0,
});

const FooterRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 10px',
  paddingRight: '15px',
});

const CreditLink = styled(MuiLink)({
  color: '#fff',
  textDecoration: 'none',
  fontWeight: 'bold',
  fontSize: '16px',
});

const SocialList = styled('ul')({
  display: 'flex',
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  columnGap: '10px',
  alignItems: 'center',
});

const SocialItem = styled('li')({
  display: 'flex',
});

const SocialLink = styled(MuiLink)({
  display: 'flex',
  background: '#fff',
  height: '30px',
  width: '30px',
  borderRadius: '4px',
  alignItems: 'center',
  justifyContent: 'center',
});

const SocialIcon = styled('img')({
  maxWidth: '20px',
});

const CreativeHeroSection = () => (
  <>
    <HeroSection>
      <BackgroundImage />
      <BackgroundOverlay />
      <Container className="auto-container">
        <ContentBox>
          <HeroSubtitle className='custom-second' variant="subtitle1">VENTA POR MENOR Y MAYOR</HeroSubtitle>
          <HeroTitle  className='custom-second' variant="h1" >Persianas <span className="lowercase">de</span> aluminio inyectado con poliuretano <span className="lowercase">y de</span> PVC</HeroTitle>
          <HeroDesc  variant="body1">
          <SupportAgentIcon sx={{color:'#DAA520', border: '2px solid #000', background:'#000', borderRadius:'50%'}}/>  Ofrecemos <b>calidad, rápida entrega y buen asesoramiento</b>.
          <br/> 
          <SettingsIcon  sx={{color:'#DAA520', border: '2px solid #000', background:'#000', borderRadius:'50%'}}/> Las lamas de aluminio inyectadas se caracterizan por su <b>aislamiento al frío, calor, ruidos y fácil mantenimiento.</b>
          <br/> 
          <FamilyRestroomIcon  sx={{color:'#DAA520', border: '2px solid #000', background:'#000', borderRadius:'50%'}}/> Una <b>Empresa Familiar</b> hace más de 30 años  de experiencia en el mercado.
          </HeroDesc>
          <Box className="btn-box">
            <CtaButton  href="https://walink.co/5a9e53">
              COMUNICATE <AppShortcutIcon />
            </CtaButton>
          </Box>
        </ContentBox>
      </Container>
    </HeroSection>

    <Footer>
      <Container>
        <FooterRow>
          <CreditLink className='custom-title' href="https://www.instagram.com/ferracort__?igsh=b3d3dHc3NmhtcmMz">
            VISITA NUESTRO INSTAGRAM
          </CreditLink>
          <SocialList>
            
            <SocialItem>
              <SocialLink href="https://www.instagram.com/ferracort__?igsh=b3d3dHc3NmhtcmMz" title="Instagram">
                <SocialIcon src="https://img.icons8.com/?size=100&id=Xy10Jcu1L2Su&format=png&color=000000" alt="Contact on Instagram" />
              </SocialLink>
            </SocialItem>
          
          </SocialList>
        </FooterRow>
      </Container>
    </Footer>
  </>
);

export default CreativeHeroSection;
