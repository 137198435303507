import React from 'react';
import { Container, Typography, Card, CardContent, CardMedia } from '@mui/material';
import constructionImage from '../assets/img/nosotros.jpg'; // Asegúrate de tener una imagen de construcción en tu proyecto

const AboutUs = () => {
  return (
    <Container>
      <Typography variant="h2" align="center" gutterBottom>
        Nosotros
      </Typography>
      <Card>
        <CardMedia
          component="img"
          height="350"
         
          image={constructionImage}
          alt="Imagen de construcción"
        />
        <CardContent>
          <Typography className="custom-title" variant="h4" textAlign='center' gutterBottom>
            Sobre Nuestra Empresa
          </Typography>
          <Typography className="custom-title" variant="h6" textAlign='center' gutterBottom>
            Calidad, Entrega Rápida y Asesoramiento Experto
          </Typography>
          <Typography  className="Playfair Display" variant="body1" sx={{letterSpacing:'1.2px', wordSpacing:'1.3px', textAlign: 'justify'}} paragraph>
          En <b className='custom-logo'>FERRACORT</b>, ofrecemos productos de alta calidad, entrega rápida y asesoramiento experto. Con más de 30 años de experiencia como empresa familiar, brindamos soluciones que mejoran la vida de nuestros clientes.
          </Typography>
          <Typography className="custom-title" variant="h6" textAlign='center' gutterBottom>
            Innovación y Eficiencia en Cada Producto
          </Typography>
          <Typography className="Playfair Display"  variant="body1" sx={{letterSpacing:'1.2px', wordSpacing:'1.3px', textAlign: 'justify'}} paragraph>
            Nuestras lamas de aluminio inyectadas destacan por su aislamiento térmico y acústico, ofreciendo una barrera efectiva contra el frío, el calor y el ruido. Su fácil mantenimiento garantiza beneficios con mínimo esfuerzo.
          </Typography>
          <Typography className="custom-title" variant="h6" textAlign='center' gutterBottom>
              Una Historia de Compromiso y Excelencia
          </Typography>
          <Typography className="Playfair Display"  variant="body1" sx={{letterSpacing:'1.2px', wordSpacing:'1.3px', textAlign: 'justify'}} paragraph>
          Nuestra empresa nació de un sueño familiar para ofrecer soluciones duraderas. Con más de 30 años de perfeccionamiento, nos mantenemos a la vanguardia tecnológica. La confianza de nuestros clientes es nuestro mayor logro. Nos comprometemos a escuchar sus necesidades y ofrecer un servicio personalizado, con expertos siempre disponibles para asesorar.
          </Typography>
          
        </CardContent>
      </Card>
    </Container>
  );
};

export default AboutUs;