export const loadImages = (context) => {
  const images = [];
  context.keys().forEach((key) => {
    try {
      images.push(context(key));
    } catch (error) {
      console.error(`Error loading image: ${key}`, error);
    }
  });
  return images;
};
